<template>
  <div
    class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-60"
    style="z-index: 9999"
  >
    <img
      src="/assets/images/logo_md.gif"
      alt="Loading..."
      class="max-h-24 max-w-24"
    />
  </div>
</template>

<script setup lang="ts"></script>
