import { defineStore } from "pinia";

export const useFullscreenLoaderStore = defineStore("fullScreenLoaderStore", {
  state: () => ({
    showFullscreenLoader: false,
  }),
  actions: {
    showLoader() {
      this.showFullscreenLoader = true;
    },
    hideLoader() {
      this.showFullscreenLoader = false;
    },
  },
});
