/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation requestForgotPasswordLink(\n      $input: RequestForgotPasswordLinkInput!\n    ) {\n      requestForgotPasswordLink(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  ": types.RequestForgotPasswordLinkDocument,
    "\n    mutation resetForgottenPassword($input: ResetForgottenPasswordInput!) {\n      resetForgottenPassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  ": types.ResetForgottenPasswordDocument,
    "\n    query getActivityLogByUser {\n      getActivityLogByUser {\n        status {\n          success\n          identifier\n          message\n        }\n        activityLogs {\n          type\n          challenge {\n            slug\n            challengeName\n            challengeReward\n          }\n          user {\n            username\n            displayName\n            avatar\n          }\n          timestamp\n        }\n      }\n    }\n  ": types.GetActivityLogByUserDocument,
    "\n    mutation initializePayment($amount: Int!, $currentUrl: String!) {\n      InitializePayment(input: { amount: $amount, originUrl: $currentUrl }) {\n        payment_url\n        status {\n          success\n          message\n          identifier\n        }\n      }\n    }\n  ": types.InitializePaymentDocument,
    "\n  mutation createChallenge($input: ChallengeInput!) {\n    createChallenge(input: $input) {\n      challenges {\n        slug\n        challengeName\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.CreateChallengeDocument,
    "\n  query getChallengeBySlug($input: ChallengeSlugInput!) {\n    getChallengeBySlug(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        challengeName\n        challengeDescription\n        challengeReward\n        challengeType\n        challengeVideos {\n          id\n          videoSource\n          aspectMode\n          downloadUrl\n        }\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        expirationDate\n        id\n        owner {\n          username\n          displayName\n          avatar\n        }\n        status\n        judgedByReferee\n        tags {\n          name\n        }\n      }\n    }\n  }\n": types.GetChallengeBySlugDocument,
    "\n  query getChallengesByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenges {\n        slug\n        status\n        challengeName\n        challengeReward\n        expirationDate\n        owner {\n          avatar\n          username\n          displayName\n        }\n        challengedUser {\n          avatar\n          displayName\n        }\n      }\n    }\n  }\n": types.GetChallengesByStatusDocument,
    "\n  query getChallengesCountByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      totalChallengesCount\n    }\n  }\n": types.GetChallengesCountByStatusDocument,
    "\n  mutation updateChallengeStatus($input: ChallengeUpdateStatusInput!) {\n    updateChallengeStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        status\n        owner {\n          username\n        }\n        challengedUser {\n          username\n        }\n      }\n    }\n  }\n": types.UpdateChallengeStatusDocument,
    "\n  mutation updateChallenge($input: UpdateChallengeInput!) {\n    updateChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.UpdateChallengeDocument,
    "\n  mutation createChallengeVideo($input: ChallengeVideoInput!) {\n    createChallengeVideo(input: $input) {\n      id\n      videoSource\n      status {\n        success\n        message\n      }\n    }\n  }\n": types.CreateChallengeVideoDocument,
    "\n  query getDashboardChallenges {\n    getDashboardChallenges {\n      runningChallengesCount\n      challengeRequestsCount\n      runningChallenges {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      challengeRequests {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.GetDashboardChallengesDocument,
    "\n  mutation shareChallengeLink($input: ShareChallengeLinkInput!) {\n    shareChallengeLink(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.ShareChallengeLinkDocument,
    "\n  query getEmailPreferences($input: GetEmailPreferencesInput!) {\n    getEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.GetEmailPreferencesDocument,
    "\n  mutation updateEmailPreferences($input: UpdateEmailPreferencesInput!) {\n    updateEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.UpdateEmailPreferencesDocument,
    "\n  query paginatedListOfBannedUsers($type: String, $page: Int) {\n    paginatedListOfBannedUsers(input: { type: $type, page: $page }) {\n      status {\n        success\n        identifier\n        message\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n      items {\n        username\n        id\n      }\n    }\n  }\n": types.PaginatedListOfBannedUsersDocument,
    "\n  query paginatedListOfReports($type: String, $page: Int) {\n    paginatedListOfReports(input: { type: $type, page: $page }) {\n      items {\n        title\n        url\n        slug\n        id\n        entityId\n        reporter {\n          username\n        }\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n    }\n  }\n": types.PaginatedListOfReportsDocument,
    "\n  query paginatedListOfRefereeChallenges($page: Int, $itemsPerPage: Int) {\n    paginatedListOfRefereeChallenges(\n      input: { page: $page, itemsPerPage: $itemsPerPage }\n    ) {\n      items {\n        id\n        challengeName\n        slug\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        owner {\n          username\n          displayName\n          avatar\n        }\n      }\n      pagination {\n        totalPages\n        currentPage\n        totalCount\n      }\n    }\n  }\n": types.PaginatedListOfRefereeChallengesDocument,
    "\n  query paginatedListOfPayoutRequests(\n    $page: Int\n    $itemsPerPage: Int\n    $status: String\n  ) {\n    paginatedListOfPayoutRequests(\n      input: { page: $page, itemsPerPage: $itemsPerPage, status: $status }\n    ) {\n      items {\n        id\n        name\n        amount\n        accountNumber\n        address\n        postalCode\n        city\n        createdAt\n      }\n    }\n  }\n": types.PaginatedListOfPayoutRequestsDocument,
    "\n  mutation createReport($input: ReportMutationInput!) {\n    createReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.CreateReportDocument,
    "\n  mutation deleteReport($input: ReactOnReportMutationInput!) {\n    deleteReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.DeleteReportDocument,
    "\n  mutation unbanUser($input: UnbanUserInput!) {\n    unbanUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.UnbanUserDocument,
    "\n  mutation banUser($input: BanUserInput!) {\n    banUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.BanUserDocument,
    "\n  mutation setPayoutRequestAsCompleted($id: String!) {\n    setPayoutRequestAsCompleted(input: { id: $id }) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.SetPayoutRequestAsCompletedDocument,
    "\n  query getProfilePaymentInformation {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentName\n        paymentAccountNumber\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentCity\n      }\n    }\n  }\n": types.GetProfilePaymentInformationDocument,
    "\n  query getPaymentDataForWallet {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentAccountNumber\n        paymentName\n      }\n    }\n  }\n": types.GetPaymentDataForWalletDocument,
    "\n  mutation updateProfilePaymentInformation(\n    $input: ProfilePaymentInformationInput!\n  ) {\n    updateProfilePaymentInformation(input: $input) {\n      profilePaymentInformation {\n        paymentName\n        paymentCity\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentAccountNumber\n      }\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.UpdateProfilePaymentInformationDocument,
    "\n  query specificUserProfile($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        id\n        username\n        displayName\n        bio\n        avatar\n        challengeable\n        publicProfile\n        respectedVideosCount\n        completedChallengesCount\n        runningChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n": types.SpecificUserProfileDocument,
    "\n  query countsQuery($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideosCount\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n": types.CountsQueryDocument,
    "\n  query uploadedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        uploadedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            thumbnailUrl\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n": types.UploadedVideosDocument,
    "\n  query runningChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        runningChallenges {\n          challengeName\n          challengeDescription\n          challengeReward\n          challengeType\n          challengeLikesCount\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          slug\n        }\n      }\n    }\n  }\n": types.RunningChallengesDocument,
    "\n  query completedChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        completedChallenges {\n          slug\n          challengeName\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          challengeDescription\n          challengeReward\n        }\n      }\n    }\n  }\n": types.CompletedChallengesDocument,
    "\n  query respectedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n": types.RespectedVideosDocument,
    "\n  mutation likeChallenge($input: ChallengeLikeInput!) {\n    likeChallenge(input: $input) {\n      liked\n      unliked\n    }\n  }\n": types.LikeChallengeDocument,
    "\n  query getSettings($username: String!) {\n    getUserProfile(username: $username) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n        challengeable\n        hasPassword\n      }\n    }\n  }\n": types.GetSettingsDocument,
    "\n  mutation updateProfile($input: ProfileInput!) {\n    updateProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        avatar\n        displayName\n        bio\n      }\n    }\n  }\n": types.UpdateProfileDocument,
    "\n  mutation updateChallengeableProfile($input: ChallengeableInput!) {\n    updateChallengeableProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        challengeable\n      }\n    }\n  }\n": types.UpdateChallengeableProfileDocument,
    "\n  mutation updateMinimalChallenge($input: MinimumChallengeInput!) {\n    updateMinimalChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n      }\n    }\n  }\n": types.UpdateMinimalChallengeDocument,
    "\n  mutation verifyUser($input: VerifyUserInput!) {\n    verifyUser(input: $input) {\n      accessToken\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n": types.VerifyUserDocument,
    "\n  query getCurrentUserProfile {\n    getCurrentUserProfile {\n      profile {\n        username\n        displayName\n        bio\n        avatar\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n        respectedVideosCount\n        roles\n      }\n    }\n  }\n": types.GetCurrentUserProfileDocument,
    "\n  query getActivityLogByUser {\n    getActivityLogByUser {\n      status {\n        success\n        identifier\n        message\n      }\n      activityLogs {\n        type\n        challenge {\n          slug\n          challengeName\n          challengeReward\n        }\n        user {\n          username\n          displayName\n          avatar\n        }\n        timestamp\n      }\n    }\n  }\n": types.GetActivityLogByUserDocument,
    "\n  query getUserBalance {\n    getWallet {\n      status {\n        success\n        message\n        identifier\n      }\n      wallet {\n        availableAmount\n        totalAmount\n        freezeAmount\n      }\n    }\n  }\n": types.GetUserBalanceDocument,
    "\n  query getOpenPayoutAmount {\n    getOpenPayoutAmount {\n      status {\n        success\n        message\n        identifier\n      }\n      amount\n    }\n  }\n": types.GetOpenPayoutAmountDocument,
    "\n  mutation payoutRequest($input: PayoutRequestInput!) {\n    payoutRequest(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n": types.PayoutRequestDocument,
    "\n    mutation dummy($email: String!, $expires: Int!, $hash: String!) {\n      verifyLoginLink(\n        input: { email: $email, expires: $expires, hash: $hash }\n      ) {\n        email\n        accessToken\n      }\n    }\n  ": types.DummyDocument,
    "\n    mutation ChangePassword($input: ChangePasswordInput!) {\n      changePassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  ": types.ChangePasswordDocument,
    "\n        mutation requestMagicLink($email: String!, $originUrl: String!) {\n          requestLoginLink(input: { email: $email, originUrl: $originUrl }) {\n            email\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      ": types.RequestMagicLinkDocument,
    "\n        mutation verifyLoginLink(\n          $email: String!\n          $expires: Int!\n          $hash: String!\n        ) {\n          verifyLoginLink(\n            input: { email: $email, expires: $expires, hash: $hash }\n          ) {\n            isVerified\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      ": types.VerifyLoginLinkDocument,
    "\n        mutation VerifyLoginCode($email: String!, $code: String!) {\n          verifyLoginCode(input: { email: $email, code: $code }) {\n            isVerified\n            accessToken\n            originUrl\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      ": types.VerifyLoginCodeDocument,
    "\n        mutation loginWithAccount($username: String!, $password: String!) {\n          login(input: { username: $username, password: $password }) {\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      ": types.LoginWithAccountDocument,
    "\n        mutation logout {\n          logout {\n            status {\n              message\n              success\n            }\n          }\n        }\n      ": types.LogoutDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation requestForgotPasswordLink(\n      $input: RequestForgotPasswordLinkInput!\n    ) {\n      requestForgotPasswordLink(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation requestForgotPasswordLink(\n      $input: RequestForgotPasswordLinkInput!\n    ) {\n      requestForgotPasswordLink(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation resetForgottenPassword($input: ResetForgottenPasswordInput!) {\n      resetForgottenPassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation resetForgottenPassword($input: ResetForgottenPasswordInput!) {\n      resetForgottenPassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getActivityLogByUser {\n      getActivityLogByUser {\n        status {\n          success\n          identifier\n          message\n        }\n        activityLogs {\n          type\n          challenge {\n            slug\n            challengeName\n            challengeReward\n          }\n          user {\n            username\n            displayName\n            avatar\n          }\n          timestamp\n        }\n      }\n    }\n  "): (typeof documents)["\n    query getActivityLogByUser {\n      getActivityLogByUser {\n        status {\n          success\n          identifier\n          message\n        }\n        activityLogs {\n          type\n          challenge {\n            slug\n            challengeName\n            challengeReward\n          }\n          user {\n            username\n            displayName\n            avatar\n          }\n          timestamp\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation initializePayment($amount: Int!, $currentUrl: String!) {\n      InitializePayment(input: { amount: $amount, originUrl: $currentUrl }) {\n        payment_url\n        status {\n          success\n          message\n          identifier\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation initializePayment($amount: Int!, $currentUrl: String!) {\n      InitializePayment(input: { amount: $amount, originUrl: $currentUrl }) {\n        payment_url\n        status {\n          success\n          message\n          identifier\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createChallenge($input: ChallengeInput!) {\n    createChallenge(input: $input) {\n      challenges {\n        slug\n        challengeName\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createChallenge($input: ChallengeInput!) {\n    createChallenge(input: $input) {\n      challenges {\n        slug\n        challengeName\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getChallengeBySlug($input: ChallengeSlugInput!) {\n    getChallengeBySlug(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        challengeName\n        challengeDescription\n        challengeReward\n        challengeType\n        challengeVideos {\n          id\n          videoSource\n          aspectMode\n          downloadUrl\n        }\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        expirationDate\n        id\n        owner {\n          username\n          displayName\n          avatar\n        }\n        status\n        judgedByReferee\n        tags {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getChallengeBySlug($input: ChallengeSlugInput!) {\n    getChallengeBySlug(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        challengeName\n        challengeDescription\n        challengeReward\n        challengeType\n        challengeVideos {\n          id\n          videoSource\n          aspectMode\n          downloadUrl\n        }\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        expirationDate\n        id\n        owner {\n          username\n          displayName\n          avatar\n        }\n        status\n        judgedByReferee\n        tags {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getChallengesByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenges {\n        slug\n        status\n        challengeName\n        challengeReward\n        expirationDate\n        owner {\n          avatar\n          username\n          displayName\n        }\n        challengedUser {\n          avatar\n          displayName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getChallengesByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenges {\n        slug\n        status\n        challengeName\n        challengeReward\n        expirationDate\n        owner {\n          avatar\n          username\n          displayName\n        }\n        challengedUser {\n          avatar\n          displayName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getChallengesCountByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      totalChallengesCount\n    }\n  }\n"): (typeof documents)["\n  query getChallengesCountByStatus($input: ChallengesByStatusInput!) {\n    getChallengesByStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      totalChallengesCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateChallengeStatus($input: ChallengeUpdateStatusInput!) {\n    updateChallengeStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        status\n        owner {\n          username\n        }\n        challengedUser {\n          username\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateChallengeStatus($input: ChallengeUpdateStatusInput!) {\n    updateChallengeStatus(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      challenge {\n        slug\n        status\n        owner {\n          username\n        }\n        challengedUser {\n          username\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateChallenge($input: UpdateChallengeInput!) {\n    updateChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateChallenge($input: UpdateChallengeInput!) {\n    updateChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createChallengeVideo($input: ChallengeVideoInput!) {\n    createChallengeVideo(input: $input) {\n      id\n      videoSource\n      status {\n        success\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createChallengeVideo($input: ChallengeVideoInput!) {\n    createChallengeVideo(input: $input) {\n      id\n      videoSource\n      status {\n        success\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDashboardChallenges {\n    getDashboardChallenges {\n      runningChallengesCount\n      challengeRequestsCount\n      runningChallenges {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      challengeRequests {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDashboardChallenges {\n    getDashboardChallenges {\n      runningChallengesCount\n      challengeRequestsCount\n      runningChallenges {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      challengeRequests {\n        id\n        challengeName\n        challengeDescription\n        challengeType\n        challengeReward\n        expirationDate\n        slug\n        owner {\n          username\n          avatar\n        }\n        challengedUser {\n          username\n          avatar\n        }\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation shareChallengeLink($input: ShareChallengeLinkInput!) {\n    shareChallengeLink(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation shareChallengeLink($input: ShareChallengeLinkInput!) {\n    shareChallengeLink(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getEmailPreferences($input: GetEmailPreferencesInput!) {\n    getEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query getEmailPreferences($input: GetEmailPreferencesInput!) {\n    getEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateEmailPreferences($input: UpdateEmailPreferencesInput!) {\n    updateEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateEmailPreferences($input: UpdateEmailPreferencesInput!) {\n    updateEmailPreferences(input: $input) {\n      emailPreferences {\n        newChallenge\n        updateChallenge\n        completedChallenge\n        marketing\n      }\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query paginatedListOfBannedUsers($type: String, $page: Int) {\n    paginatedListOfBannedUsers(input: { type: $type, page: $page }) {\n      status {\n        success\n        identifier\n        message\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n      items {\n        username\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query paginatedListOfBannedUsers($type: String, $page: Int) {\n    paginatedListOfBannedUsers(input: { type: $type, page: $page }) {\n      status {\n        success\n        identifier\n        message\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n      items {\n        username\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query paginatedListOfReports($type: String, $page: Int) {\n    paginatedListOfReports(input: { type: $type, page: $page }) {\n      items {\n        title\n        url\n        slug\n        id\n        entityId\n        reporter {\n          username\n        }\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query paginatedListOfReports($type: String, $page: Int) {\n    paginatedListOfReports(input: { type: $type, page: $page }) {\n      items {\n        title\n        url\n        slug\n        id\n        entityId\n        reporter {\n          username\n        }\n      }\n      pagination {\n        currentPage\n        totalPages\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query paginatedListOfRefereeChallenges($page: Int, $itemsPerPage: Int) {\n    paginatedListOfRefereeChallenges(\n      input: { page: $page, itemsPerPage: $itemsPerPage }\n    ) {\n      items {\n        id\n        challengeName\n        slug\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        owner {\n          username\n          displayName\n          avatar\n        }\n      }\n      pagination {\n        totalPages\n        currentPage\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query paginatedListOfRefereeChallenges($page: Int, $itemsPerPage: Int) {\n    paginatedListOfRefereeChallenges(\n      input: { page: $page, itemsPerPage: $itemsPerPage }\n    ) {\n      items {\n        id\n        challengeName\n        slug\n        challengedUser {\n          username\n          displayName\n          avatar\n        }\n        owner {\n          username\n          displayName\n          avatar\n        }\n      }\n      pagination {\n        totalPages\n        currentPage\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query paginatedListOfPayoutRequests(\n    $page: Int\n    $itemsPerPage: Int\n    $status: String\n  ) {\n    paginatedListOfPayoutRequests(\n      input: { page: $page, itemsPerPage: $itemsPerPage, status: $status }\n    ) {\n      items {\n        id\n        name\n        amount\n        accountNumber\n        address\n        postalCode\n        city\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query paginatedListOfPayoutRequests(\n    $page: Int\n    $itemsPerPage: Int\n    $status: String\n  ) {\n    paginatedListOfPayoutRequests(\n      input: { page: $page, itemsPerPage: $itemsPerPage, status: $status }\n    ) {\n      items {\n        id\n        name\n        amount\n        accountNumber\n        address\n        postalCode\n        city\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createReport($input: ReportMutationInput!) {\n    createReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createReport($input: ReportMutationInput!) {\n    createReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteReport($input: ReactOnReportMutationInput!) {\n    deleteReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteReport($input: ReactOnReportMutationInput!) {\n    deleteReport(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation unbanUser($input: UnbanUserInput!) {\n    unbanUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation unbanUser($input: UnbanUserInput!) {\n    unbanUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation banUser($input: BanUserInput!) {\n    banUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation banUser($input: BanUserInput!) {\n    banUser(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setPayoutRequestAsCompleted($id: String!) {\n    setPayoutRequestAsCompleted(input: { id: $id }) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation setPayoutRequestAsCompleted($id: String!) {\n    setPayoutRequestAsCompleted(input: { id: $id }) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProfilePaymentInformation {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentName\n        paymentAccountNumber\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentCity\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProfilePaymentInformation {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentName\n        paymentAccountNumber\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentCity\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPaymentDataForWallet {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentAccountNumber\n        paymentName\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPaymentDataForWallet {\n    getProfilePaymentInformation {\n      profilePaymentInformation {\n        paymentAccountNumber\n        paymentName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProfilePaymentInformation(\n    $input: ProfilePaymentInformationInput!\n  ) {\n    updateProfilePaymentInformation(input: $input) {\n      profilePaymentInformation {\n        paymentName\n        paymentCity\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentAccountNumber\n      }\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateProfilePaymentInformation(\n    $input: ProfilePaymentInformationInput!\n  ) {\n    updateProfilePaymentInformation(input: $input) {\n      profilePaymentInformation {\n        paymentName\n        paymentCity\n        paymentAddress\n        paymentBic\n        paymentPostalCode\n        paymentAccountNumber\n      }\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query specificUserProfile($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        id\n        username\n        displayName\n        bio\n        avatar\n        challengeable\n        publicProfile\n        respectedVideosCount\n        completedChallengesCount\n        runningChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query specificUserProfile($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        id\n        username\n        displayName\n        bio\n        avatar\n        challengeable\n        publicProfile\n        respectedVideosCount\n        completedChallengesCount\n        runningChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query countsQuery($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideosCount\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query countsQuery($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideosCount\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query uploadedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        uploadedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            thumbnailUrl\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query uploadedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        uploadedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            thumbnailUrl\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query runningChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        runningChallenges {\n          challengeName\n          challengeDescription\n          challengeReward\n          challengeType\n          challengeLikesCount\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          slug\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query runningChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        runningChallenges {\n          challengeName\n          challengeDescription\n          challengeReward\n          challengeType\n          challengeLikesCount\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          slug\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query completedChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        completedChallenges {\n          slug\n          challengeName\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          challengeDescription\n          challengeReward\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query completedChallenges($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        completedChallenges {\n          slug\n          challengeName\n          visibility\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n          challengeDescription\n          challengeReward\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query respectedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query respectedVideos($username: String!, $showPrivate: Boolean = false) {\n    getUserProfile(username: $username, showPrivate: $showPrivate) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        respectedVideos {\n          slug\n          challengeName\n          challengeDescription\n          challengeReward\n          hasLiked\n          challengeLikesCount\n          challengeLikes {\n            user {\n              username\n              displayName\n              avatar\n            }\n          }\n          challengeVideos {\n            videoSource\n            aspectMode\n          }\n          owner {\n            username\n            avatar\n          }\n          challengedUser {\n            username\n            avatar\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation likeChallenge($input: ChallengeLikeInput!) {\n    likeChallenge(input: $input) {\n      liked\n      unliked\n    }\n  }\n"): (typeof documents)["\n  mutation likeChallenge($input: ChallengeLikeInput!) {\n    likeChallenge(input: $input) {\n      liked\n      unliked\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSettings($username: String!) {\n    getUserProfile(username: $username) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n        challengeable\n        hasPassword\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSettings($username: String!) {\n    getUserProfile(username: $username) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n        challengeable\n        hasPassword\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProfile($input: ProfileInput!) {\n    updateProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        avatar\n        displayName\n        bio\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateProfile($input: ProfileInput!) {\n    updateProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        avatar\n        displayName\n        bio\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateChallengeableProfile($input: ChallengeableInput!) {\n    updateChallengeableProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        challengeable\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateChallengeableProfile($input: ChallengeableInput!) {\n    updateChallengeableProfile(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        challengeable\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMinimalChallenge($input: MinimumChallengeInput!) {\n    updateMinimalChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateMinimalChallenge($input: MinimumChallengeInput!) {\n    updateMinimalChallenge(input: $input) {\n      status {\n        success\n        identifier\n        message\n      }\n      profile {\n        minimumChallengeEnabled\n        minimumChallengeValue\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation verifyUser($input: VerifyUserInput!) {\n    verifyUser(input: $input) {\n      accessToken\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation verifyUser($input: VerifyUserInput!) {\n    verifyUser(input: $input) {\n      accessToken\n      status {\n        success\n        identifier\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCurrentUserProfile {\n    getCurrentUserProfile {\n      profile {\n        username\n        displayName\n        bio\n        avatar\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n        respectedVideosCount\n        roles\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCurrentUserProfile {\n    getCurrentUserProfile {\n      profile {\n        username\n        displayName\n        bio\n        avatar\n        runningChallengesCount\n        completedChallengesCount\n        uploadedVideosCount\n        respectedVideosCount\n        roles\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getActivityLogByUser {\n    getActivityLogByUser {\n      status {\n        success\n        identifier\n        message\n      }\n      activityLogs {\n        type\n        challenge {\n          slug\n          challengeName\n          challengeReward\n        }\n        user {\n          username\n          displayName\n          avatar\n        }\n        timestamp\n      }\n    }\n  }\n"): (typeof documents)["\n  query getActivityLogByUser {\n    getActivityLogByUser {\n      status {\n        success\n        identifier\n        message\n      }\n      activityLogs {\n        type\n        challenge {\n          slug\n          challengeName\n          challengeReward\n        }\n        user {\n          username\n          displayName\n          avatar\n        }\n        timestamp\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserBalance {\n    getWallet {\n      status {\n        success\n        message\n        identifier\n      }\n      wallet {\n        availableAmount\n        totalAmount\n        freezeAmount\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUserBalance {\n    getWallet {\n      status {\n        success\n        message\n        identifier\n      }\n      wallet {\n        availableAmount\n        totalAmount\n        freezeAmount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOpenPayoutAmount {\n    getOpenPayoutAmount {\n      status {\n        success\n        message\n        identifier\n      }\n      amount\n    }\n  }\n"): (typeof documents)["\n  query getOpenPayoutAmount {\n    getOpenPayoutAmount {\n      status {\n        success\n        message\n        identifier\n      }\n      amount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation payoutRequest($input: PayoutRequestInput!) {\n    payoutRequest(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation payoutRequest($input: PayoutRequestInput!) {\n    payoutRequest(input: $input) {\n      status {\n        success\n        message\n        identifier\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation dummy($email: String!, $expires: Int!, $hash: String!) {\n      verifyLoginLink(\n        input: { email: $email, expires: $expires, hash: $hash }\n      ) {\n        email\n        accessToken\n      }\n    }\n  "): (typeof documents)["\n    mutation dummy($email: String!, $expires: Int!, $hash: String!) {\n      verifyLoginLink(\n        input: { email: $email, expires: $expires, hash: $hash }\n      ) {\n        email\n        accessToken\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ChangePassword($input: ChangePasswordInput!) {\n      changePassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation ChangePassword($input: ChangePasswordInput!) {\n      changePassword(input: $input) {\n        status {\n          success\n          message\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation requestMagicLink($email: String!, $originUrl: String!) {\n          requestLoginLink(input: { email: $email, originUrl: $originUrl }) {\n            email\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "): (typeof documents)["\n        mutation requestMagicLink($email: String!, $originUrl: String!) {\n          requestLoginLink(input: { email: $email, originUrl: $originUrl }) {\n            email\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation verifyLoginLink(\n          $email: String!\n          $expires: Int!\n          $hash: String!\n        ) {\n          verifyLoginLink(\n            input: { email: $email, expires: $expires, hash: $hash }\n          ) {\n            isVerified\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "): (typeof documents)["\n        mutation verifyLoginLink(\n          $email: String!\n          $expires: Int!\n          $hash: String!\n        ) {\n          verifyLoginLink(\n            input: { email: $email, expires: $expires, hash: $hash }\n          ) {\n            isVerified\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation VerifyLoginCode($email: String!, $code: String!) {\n          verifyLoginCode(input: { email: $email, code: $code }) {\n            isVerified\n            accessToken\n            originUrl\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "): (typeof documents)["\n        mutation VerifyLoginCode($email: String!, $code: String!) {\n          verifyLoginCode(input: { email: $email, code: $code }) {\n            isVerified\n            accessToken\n            originUrl\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation loginWithAccount($username: String!, $password: String!) {\n          login(input: { username: $username, password: $password }) {\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "): (typeof documents)["\n        mutation loginWithAccount($username: String!, $password: String!) {\n          login(input: { username: $username, password: $password }) {\n            accessToken\n            status {\n              success\n              identifier\n              message\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation logout {\n          logout {\n            status {\n              message\n              success\n            }\n          }\n        }\n      "): (typeof documents)["\n        mutation logout {\n          logout {\n            status {\n              message\n              success\n            }\n          }\n        }\n      "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;